$workspaceWidth: 50vw;
$canvasWidth: 400px;
$canvasHeight: 400px;

.workspace {
  height: 100vh;
  width : 50vw;
  padding: 30px 20px;
  text-align: center;
  box-sizing: border-box;
  color: white;
  .ReactCrop {
    width: $canvasWidth;
    height: $canvasHeight;
    background-color: transparent;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 0px 20px grey;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    & div > img {
      width: $canvasWidth;
      height: $canvasHeight;
    }
    .gridContainer {
      width: $canvasWidth;
      height: $canvasHeight;
      position: absolute;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      pointer-events: none;
    }
    .ReactCrop__crop-selection {
      box-shadow: 0 0 0 9999em rgb(48 48 48 / 50%);
    }
  }
}
