// tailwind 
@tailwind base;
@tailwind components;
@tailwind utilities;

// themes
@import "./themes/default.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  .title {
    @apply text-white text-lg font-extrabold mb-4 ;
  }
  .field {
    @apply text-white font-medium px-4 py-3 bg-lightGrey mb-2 cursor-pointer rounded-lg;
  }
}
.plusIcon {
  width: 34px;
  height: 33px;
  border: 2px dotted grey;
  padding: 0px;
  padding-left: 3px;
  padding-top: 1px;
  border-radius: 50%;
  cursor: pointer;
  svg {
    color: lightgray;
  }
}
@mixin dividerPeudoClassValues {
  content:'';
    width: 45%;
    height: 1px;
    position: absolute;
    background-color: grey;
    top:53%;
}
.orDivider
{
  margin: 0;
  padding: 0;
  color: rgb(212, 211, 211);
  &:after
  {
    @include dividerPeudoClassValues();
    right: 0;
  }
  &::before
  { @include dividerPeudoClassValues();
    left: 0;
  }
}