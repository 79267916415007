.layersContainer {
  min-width: 200px;
  height: 100vh;
  width: 25vw;
  box-shadow: -1px 3px 9px var(--shadow-color);
  .layers {
    overflow: scroll;
    height: calc(100vh - 149px);
    .activeLayer {
      border: 2px solid var(--btn-bg-color);
      background-color: #413630;
    }
    input {
      padding: 6px 8px;
      width: 89%;
      border: none;
      outline: none;
      background: #2e2d2d;
      color: white;
      caret-color: white;
      font-size: 18px;
    }
    p {
      
      margin-top: -6px;
      margin-right: 3px;
    }
    .addAttr 
    {
      font-size: 26px;
    }
  }
}
