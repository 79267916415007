.previews {
    // background: #383433;
    & > div:not(.plusIcon)
    {
      width: 20px;
      height: 20px;
      border: 0.5px solid white;
      & > img
      {
        width: inherit;
        height: inherit;
        object-fit: contain;
      }
    }
    .cancelIcon {
      position: absolute;
      top: -9px;
      color: #b6b6b6;
      right: -9px;
      font-size: 15px;
    }
    .active:after
    {
      content: '';
      width:100%;
      position: absolute;
      top: 125%;
      left: 0;
      border-bottom: 3px solid var(--btn-bg-color);
      border-radius: 5px;
    }
  }