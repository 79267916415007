:root {
  //background colors
  --bg-color: #2f2f2f;
  --light-grey-color: #45413a;

  //border colors
  --border-color: #414141;

  //shadow colors
  --shadow-color: #595959;

  // text colors
  --txt-color: #ff6f26;

  // button colors
  --btn-bg-color: #ff6f26;
}
