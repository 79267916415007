.layerPreview {
  height: 100vh;
  width: 25vw;
  overflow: scroll;
  flex: 24%;
  color: white;
  // padding: 24px;
  box-shadow: 1px 1px 9px var(--shadow-color);
  section {
    // text-align: center;
    img {
      display: block;
      // width: 100%;
      height: 300px;
    }
  }
  .sketch-picker
  {
    background-color: transparent !important;
    box-shadow: none !important;
    width: auto !important;
    input
    {
      background-color: var(--light-grey-color);
      box-shadow: none !important;
    }
    label
    {
      display: none !important;
    }
     .flexbox-fix:last-child
    {
      display: none !important;
    }
  }

}

